import { defineComponent, ref, Ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';

import highlightJs from 'hightlight.js';

import hljs from 'highlight.js';
import 'highlight.js/styles/atelier-heath-light.css';

interface Compat {
	[propName: string]: string | boolean | number;
}

interface DataDetail {
	onlyid: string;
	title: string;
	useCode: string[];
	compat: Compat;
	content: string;
}

export default defineComponent({
	name: 'LabEffectDetailPage',
	directives: {
		spec(el) {
			el.querySelectorAll('a').forEach(a => {
				a.setAttribute('target', '_blank');
			});
		},
		highlight(el) {
			el.querySelectorAll('pre code').forEach(block => {
				(hljs as any).highlightBlock(block as HTMLElement);
			});
		},
	},
	components: { FetchData },
	setup() {
		const dataDetail: Ref<DataDetail> = ref({
			onlyid: '',
			title: '',
			useCode: [],
			compat: {
				IE: '',
			},
			content: '',
		});
		const touchData = (data: DataDetail) => (dataDetail.value = data);
		return { touchData, dataDetail };
	},
});
